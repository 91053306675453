import 'react-app-polyfill/ie11'; // For IE 11 support
import './ie11_polyfills';

import './reset.css';
import './app.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import Spinner from './Spinner';
import * as serviceWorker from './serviceWorker';

const root = document.getElementById('survey');

ReactDOM.render(<Spinner size={100} height={500} />, root);

Promise.all([
    import('react-router-dom'),
    import('./App'),
    import('./Storage').then(Storage => Storage.getState())
])
    .then(([react_router, app_module, state]) => {
        const App = app_module.default,
            BrowserRouter = react_router.BrowserRouter;

        ReactDOM.render((
            <BrowserRouter basename="/">
                <App saved_state={state}/>
            </BrowserRouter>
        ), root);
    });

serviceWorker.register();
