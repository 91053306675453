import React from 'react';
import './Spinner.css'

export default function Spinner(props) {
    const size = props.size || 50,
        style = props.style || {};
    return <div style={Object.assign({
        minHeight: props.height ? props.height : 300,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }, props.containerStyle || {})}>
        <div className="spinner" style={Object.assign({width: size, height: size}, style)}/>
    </div>;
}